import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    unknown_error: 'An unknown error occurred. Please try again later.',
    register: 'Register',
    login: "Login",
    about_us: "About Us",
    language: "Language",
    settings: 'Settings',
    save: 'Save',
    edit: 'Edit',
    players: 'Players',
    defender: 'Defender',
    midfielder: 'Midfielder',
    attacker: 'Forward',
    statistics: 'Statistics',
    add: 'Add',
    transfers: 'Transfers',
    player_success_returned: 'The player successfully returned to the club',
    players_empty_list: 'The list of players is empty',
    player_successfully_updated: 'Player successfully updated',
    player_successfully_added: 'Player successfully added',
    player_successfully_left_the_club: 'The player successfully left the club',
    name: "Name",
    surname: 'Surname',
    number: 'Number',
    choose_position: 'Select position',
    select_player: 'Select player',
    leave_the_club: 'Leave the club',
    position: 'Position',
    player_from_api_stats: 'Player from API Stats',
    date_of_birth: 'Date of birth',
    the_news_list_is_empty: "The news-form list is empty",
    remove: 'Remove',
    news_deleted_successfully: 'News deleted successfully',
    news_successfully_changed: 'News successfully changed',
    news_successfully_added: "News successfully added",
    add_news: 'Add News',
    edit_news: 'Edit News',
    title: "Title",
    content: 'Content',
    download: 'Download',
    image: 'Image',
    return: 'Return',
    the_topics_list_is_empty: 'The topics list is empty',
    activate: 'Activate',
    deactivate: 'Deactivate',
    list_of_active_topics: 'List of active topics',
    list_of_inactive_topics: 'List of inactive topics',
    your_rating_is_missing: "Your rating is missing",
    user_account: 'User Account',
    season_rating: 'Season Rating',
    data_changed_successfully: 'Data changed successfully',
    password_changed_successfully: 'Password changed successfully',
    name_and_surname: 'Name and Surname',
    password: 'Password',
    new_password: 'New password',
    current_password: 'Current password',
    password_confirmation: 'Password confirmation',
    forum: "Forum",
    an_application_for_updating_the_topic_name_has_been_submitted: 'An application for updating the topic name has been submitted',
    an_application_has_been_submitted_to_create_a_topic: 'An application has been submitted to create a topic',
    topic_title: 'Topic title',
    the_message_list_is_empty: 'The message list is empty',
    message_text: 'message text...',
    send: 'Send',
    profile: 'Profile',
    cruspher: 'Cruspher',
    tables: 'Tables',
    news: 'News',
    player_stats: 'Player stats',
    contacts: 'Contacts',
    admin_menu: 'Admin Menu',
    logout: 'logout',
    upload: 'Upload',
    view: 'View',
    email: "Email",
    confirm_password: 'Confirm password',
    success_register: 'You are successfully registered',
    confirm_your_email: 'Confirm your email',
    success_edited: 'Success updated',
    success_added: 'Success added',
    success_removed: 'Success remover',
    forecast: 'Forecast',
    game: 'Game',
    goals: 'Goals',
    shots_on_goal: 'Shots on goal',
    shots_total: 'Shots total',
    block_shoots: 'Shots blocked',
    shot_inside_box: 'Shots inside box',
    shot_outside_box: 'Shots outside box',
    corners: 'Corners',
    offsides: 'Offsides',
    ball_possession: 'Ball possession',
    saves: 'Saves',
    passes_total: 'Passes total',
    passes_accurate: 'Passes success',
    yellow_cards: 'Yellow cards',
    red_cards: 'Red cards',
    date: 'Date',
    success_shots: "Success shots",
    passes: "Passes",
    success_passes: "Passes success",
    dribbles: "Dribbles",
    success_dribbles: "Dribbles success",
    list_is_empty: 'List is empty',
    social: 'Social',
    active_themes: 'Active thees',
    disabled_themes: 'Blocked themes',
    emotion_success_added: 'Emotion success added',
    news_language: 'News language',
    forum_language: "Forum language",
    choose: 'Chose',
    social_success_updated: 'Social links success updated',
    load_data: 'Load data',
    chose_position: "Chose position",
    playerForm: 'Player uniform',
    goalkeeperForm: 'Goalkeeper uniform',
    stadium: 'Stadium',
    type: "Type",
    shop: 'Shop',
    box: 'Box',
    shop_type: 'Shop Type',
    price: 'Price',
    coins: 'Coins',
    all_players: 'All players',
    you_already_have_this_element: 'You already bough this element',
    success_bought_check_your_inverter: 'Bought success completed, check your inverter',
    element_success_chosen: 'Element success chosed',
    congratulations: 'Congratulations!!!',
    bux_success_bought: 'The box was successfully purchased',
    rate_success_added: 'Forecast success added',
    rate_success_edited: 'Forecast success updated',
    please_check_content: "Please check the news content",
    report_success_added: 'Report success added',
    id: 'id',
    players_statuses: 'Players statuses',
    shots:"Shots",
    return_to_club: "Return to club",
    add_news_language: 'Add news language',
    trophies: 'News',
    trophy_room: 'Trophy Room',
    edit_news_language: "Edit news language",
    add_forum_language: 'Add forum language',
    edit_forum_language: 'Edi forum language',
    add_shop_element: 'Add shop element',
    levels: 'Levels',
    game_not_finish: 'Game not finished',
    forum_reports: 'Reports',
    table_success_updated: "table_success_updated",
    players_success_updated: 'players_success_updated',
    market: "Market",
    minutes_played: "Minutes Played",
    shots_on_target: "Shots on target",
    ball_accuracy: "Ball accuracy",
    passestotal:"Total passes",
    shotsongoal: "Shots on goal",
    passsesacuracy: "Passes accuracy",
    passeskey: "Key passes",
    dribblestotal: "Total dribbles",
    dribblessuccess: "Success dribbles",
    foulcommitted: "Committed fouls",
    fouldrawn: "Drawn fouls",
    yellowcars: "Yellow cards",
    redcards: "Red Cards",
    inventer: "Inventer",
    buy:"Buy",
    shots_on_goal_per_goal: "Shots on goal per goal",
    yellow_cards_games: "Yellow cards per game",
    red_cards_per_games: "Red cards per games",
    passes_per_games: "Passes per games",
    shots_inside_box_per_games: "Shots inside box per games",
    shots_on_goal_per_games: "Shots on goal per games",
    shots_on_goal_away_per_game: "Shots on goal away per game",
    team_shots_home: "Team shots home",
    team_shots_away: "Team shots away",
    team_passes_home: "Team passes home",
    team_passes_away: "Team passes away",
    opponent_shots_on_goal: "Opponent shots on goal",
    team_goals: "Team goals",
    opponent_goals: "Opponent goals",
    wrong_passes: "Wrong passes",
    win: "Win",
    draw: "Draw",
    lose: "Lose",
    matches: "Matches",
    shots_inside_box: "Shots inside box",
    shots_per_game: "Shots per game",
    shots_outside_box: "Shots outside box",
    passes_proc: "Passes %",
    passes_per_game: "Passes per game",
    success_passes_per_game: "Success passes per game",
    missed_shots: "Missed shots",
    team_goals_home: "Team goals home",
    team_goals_away: "Team goals away",
    team_shots: "Team shots",
    opponent_shots: "Opponent shots",
    team_shots_on_goal: "Team shots on goal",
    team_passes: "Team passes",
    opponent_passes: "Opponent passes",
    team_corners: "Team corners",
    opponent_corners: "Opponent corners",
    opponent_passes_accurate: "Opponent passes accurate",
    team_passes_accurate: "Team passes accurate",
    passes_home: "Passes home",
    passes_away: "Passes away",
    passes_per_goal: "Passes per goal",
    passes_home_per_goal: "Passes home per goal",
    passes_away_per_goal: "Passes away per goal",
    passes_home_per_games: "Passes home per games",
    passes_away_per_games: "Passes away per games",
    passes_accurate_home: "Passes accurate home",
    passes_accurate_away: "Passes accurate away",
    passes_accurate_per_goal: "Passes accurate per goal",
    passes_accurate_home_per_goal: "Passes accurate home per goal",
    passes_accurate_away_per_goal: "Passes accurate away per goal",
    passes_accurate_per_games: "Passes accurate per games",
    passes_accurate_home_per_games: "Passes accurate home per games",
    passes_accurate_away_per_games: "Passes accurate away per games",
    passes_accurate_proc: "Passes accurate proc",
    fouls: "Fouls",
    opponent_goal: "Opponent goal",
    games_home: "Games home",
    games_away: "Games away",
    goals_home: "Goals home",
    goals_away: "Goals away",
    goals_per_game: "Goals per game",
    goals_home_per_game: "Goals home per game",
    goals_away_per_game: "Goals away per game",
    not_have: "Not have",
    one: "One",
    two: "Two",
    more_two: "More two",
    shots_home: "Shots home",
    shots_away: "Shots away",
    goals_per_shot: "Goals per shot",
    goals_home_per_shot: "Goals home per shot",
    privacy_policy: "Privacy policy",
    goals_away_per_shot: "Goals away per shot",
    shots_per_games: "Shots per games",
    shots_home_per_games: "Shots home per games",
    shots_away_per_games: "Shots away per games",
    less_11: "Less 11",
    more_10: "More 10",
    more_15: "More 15",
    shots_on_goal_home: "Shots on goal home",
    shots_on_goal_away: "Shots on goal away",
    shots_per_goal: "Shots per goal",
    shots_on_goal_home_per_goal: "Shots on goal home per goal",
    shots_on_goal_away_per_goal: "Shots on goal away per goal",
    shots_on_goal_per_game: "Shots on goal per game",
    shots_on_goal_home_per_games: "Shots on goal home per games",
    shots_on_goal_away_per_games: "Shots on goal away per games",
    shots_inside_box_home: "Shots inside box home",
    shots_inside_box_away: "Shots inside box away",
    shots_inside_box_per_game: "Shots inside box per game",
    shots_inside_box_home_per_games: "Shots inside box home per games",
    shots_inside_box_away_per_games: "Shots inside box away per games",
    shots_outside_box_home: "Shots outside box home",
    shots_outside_box_away: "Shots outside box away",
    shots_outside_box_per_games: "Shots outside box per games",
    shots_outside_box_home_per_games: "Shots outside box home per games",
    shots_outside_box_away_per_games: "Shots outside box away per games",
    corners_home : "Corners home",
    corners_away: "Corners away",
    corners_per_game: "Corners per game",
    corners_home_per_games: "Corners home per games",
    corners_away_per_games: "Corners away per games",
    saves_home: "Saves home",
    saves_away: "Saves away",
    saves_per_games: "Saves away",
    saves_home_per_games: "Saves home per games",
    saves_away_per_games: "Saves away per games",
    fouls_home: "Fouls home",
    fouls_away: "Fouls away",
    fouls_per_games: "Fouls per games",
    fouls_home_per_games: "Fouls home per games",
    fouls_away_per_games: "Fouls away per games",
    offsides_home: "Offsides home",
    offsides_away: "Offsides away",
    offsides_per_games: "Offsides per games",
    offsides_home_per_games: "Offsides home per games",
    offsides_away_per_games: "Offsides away per games",
    yellow_cards_home: "Yellow cards home",
    yellow_cards_away: "Yellow cards away",
    yellow_cards_per_game: "Yellow cards per game",
    yellow_cards_home_per_game: "Yellow cards home per game",
    yellow_cards_away_per_game: "Yellow cards away per game",
    red_cards_home: "Red cards home",
    red_cards_away: "Red cards away",
    red_cards_per_game: "Red cards per game",
    red_cards_home_per_games: "Red cards home per games",
    red_cards_away_per_games: "Red cards away per games",
    passes_accuracy: "Passes accuracy",
    key_passes: "Key passes",
    assists: "Assists",
    dribbles_total: "Dribbles total",
    dribbles_success: "Dribbles success",
    dribbles_per_game: "Dribbles per game",
    games: "Games",
    minutes_per_game: "Minutes per game",
    game_per_goal: "Game per goal",
    minutes_per_goal: "Minutes per goal",
    passes_success: "Passes success",
    fouls_committed: "Fouls committed",
    passesAccuracy: "Passes Accuracy",
    shotsTotal: "Shots",
    shotsOnGoal: "Shots on goal",
    passesTotal: "Passes",
    passesKey: "Key passes",
    dribblesTotal: "Dribbles",
    dribblesSuccess: "Success dribbles",
    foulCommitted: "Foul committed",
    foulDrawn: "Foul Draw",
    redCards: "Red cards",
    yellowCars: "Yellow cards",
    minutes:"Minutes",
    rating:"Rating",
    passesaccuracy: "Passes accuracy",
    corners_per_games: "Corners per game",
    beginner: "Beginner",
    experienced: "Experienced",
    butcher: "Butcher",
    veteran: "Veteran",
    fan: "Fan",
    ultras: "Ultras",
    warrior: "Warrior",
    cyborg: "Cyborg",
    leader: "Leader",
    gladiator: "Gladiator",
    profi: "Profi",
    guru: "Guru",
    expert: "Expert",
    sensei: "Sensei",
    socios: "Socios",
    inverter: 'Inventer',
    goalkeeper: "Goalkeeper",
    Goalkeeper: "Goalkeeper",
    not_have_stats: "Not have stats",
    for_presentation_only: "for presentation only",
    login_name: 'Login',
    search: 'Search',
    search_before: 'Search before',
    goalkeepers: 'Goalkeepers',
    defenders: 'Defenders',
    midfielders: 'Midfielders',
    attackers: 'Attackers',
    Defender: 'Defender',
    Midfielder: 'Midfielder',
    Attacker: 'Attacker',
    missed_list: 'Will miss the match',
    filter: 'Filter',
    success_updated: 'Successfully updated',
    add_game: "Add game",
    sur_prize_title: "The Cruspher team is holding a seasonal prize draw. Make as many predictions as possible and climb up the leaderboard for a chance to win prizes at the end of the season.",
    hours_left_to_add_rate: "hour(s) left to add rate"
  },
  [LOCALES.UKRAINIAN]: {
    not_have_stats: "Статистика відсутня",
    goalkeeper: "Воротарь",
    Goalkeeper: "Воротарь",
    beginner: "Новачок",
    experienced: "Опитний",
    players: 'Гравці',
    butcher: "Мясник",
    veteran: "Бувалий",
    fan: "Фанат",
    ultras: "Ультрас",
    warrior: "Воїн",
    cyborg: "Кіборг",
    leader: "Лідер",
    gladiator: "Гладіатор",
    profi: "Профі",
    guru: "Гуру",
    expert: "Експерт",
    sensei: "Сенсей",
    socios: "Соціос",
    team_goals: "Голи команди",
    opponent_goals: "Голи опонента",
    opponent_shots_on_goal: "Удари опонента по воротах",
    team_passes_home: "Паси команди вдома",
    team_passes_away: "Паси команди на виїзді",
    team_shots_home: "Удари команди вдома",
    team_shots_away: "Удари команди на виїзді",
    shots_on_goal_per_games: "Ударів по воротах за ігри",
    shots_on_goal_away_per_game: "Ударів по воротах на виїзді за гру",
    shots_inside_box_per_games: "Ударів у ворота за ігри",
    red_cards_per_games: "Червоних карток за ігри",
    yellow_cards_games: "Жовтих карток на гру",
    shots_on_goal_per_goal: "Ударів по воротах на гол",
    inventer: "Інвентарь",
    buy:"Придбати",
    unknown_error: 'Сталася невідома помилка. Спробуйте будь ласка пізніше.',
    register: 'Реєстрація',
    login: "Вхід",
    about_us: "Про нас",
    language: "Мова",
    settings: 'Настройки',
    save: 'Зберегти',
    edit: 'Редагувати',
    defender: 'Захисник',
    midfielder: 'Півзахисник',
    attacker: 'Нападник',
    Defender: 'Захисник',
    Midfielder: 'Півзахисник',
    Attacker: 'Нападник',
    statistics: 'Статистика',
    add: 'Додати',
    transfers: 'Транфери',
    player_success_returned: 'Гравець успішно повернувся до клубу',
    players_empty_list: 'Список гравців порожній',
    player_successfully_updated: 'Гравець успіщно оновлений',
    player_successfully_added: 'Гравця успішно додано',
    player_successfully_left_the_club: 'Гравець успішно покинув клуб',
    name: "Ім'я",
    surname: 'Прізвище',
    number: 'Номер',
    choose_position: 'Обреріть позицію',
    select_player: 'Виберіть гравця',
    leave_the_club: 'Залишити клуб',
    position: 'Позиція',
    player_from_api_stats: 'Гравець із API статистики',
    date_of_birth: 'Date of birth',
    the_news_list_is_empty: "Список новин пустий",
    remove: 'Видалити',
    news_deleted_successfully: 'Новину успішно видалено',
    news_successfully_changed: 'Новину успішно змінено',
    news_successfully_added: "Новину успішно добавлено",
    add_news: 'Добавити новину',
    edit_news: 'Редагувати новину',
    title: "Назва",
    content: 'Зміст',
    download: 'Завантажити',
    image: 'Изображения',
    return: 'Повернути',
    the_topics_list_is_empty: 'Список тем порожній',
    activate: 'Активувати',
    deactivate: 'Деактивувати',
    list_of_active_topics: 'Список активних тем',
    list_of_inactive_topics: 'Список неактивних тем',
    your_rating_is_missing: "Ваша оцінка відсутня",
    user_account: 'Обліковий запис',
    season_rating: 'Рейтинг сезону',
    data_changed_successfully: 'Дані успішно змінено',
    password_changed_successfully: 'Пароль успішно змінено',
    name_and_surname: 'Імя та Фамілія',
    password: 'Пароль',
    new_password: 'Новий Пароль',
    current_password: "Поточний пароль",
    password_confirmation: 'Підтвердіть пароль',
    forum: "Форум",
    an_application_for_updating_the_topic_name_has_been_submitted: 'Подано заявку на оновлення назви теми',
    an_application_has_been_submitted_to_create_a_topic: 'Подано заявку на створення теми',
    topic_title: 'Заголовок теми',
    the_message_list_is_empty: 'Список повідомлень пустий',
    message_text: 'текст повідомлення...',
    send: 'Відправити',
    profile: 'Профіль',
    cruspher: 'Cruspher',
    tables: 'Таблиці',
    news: 'Новини',
    player_stats: 'Статистика гравців',
    contacts: 'Контакти',
    admin_menu: 'Адмін Меню',
    logout: 'Вихід',
    upload: 'Загрузити',
    view: 'Перегляд',
    email: "Емейл",
    confirm_password: 'Підтвердіть пароль',
    success_register: 'Ви успішно зареєстровані',
    confirm_your_email: 'Підтвердіть ваш е-мейл адрес',
    success_edited: 'Успішно змінено',
    success_added: 'Успішно додано',
    success_removed: 'Успішно видалено',
    forecast: 'Прогноз',
    game: 'Гра',
    goals: 'Голи',
    shots_on_goal: 'Удар по воротам',
    shots_total: 'Ударів у загальному',
    block_shoots: 'Заблоковані удари',
    shot_inside_box: 'Удари у площину воріт',
    shot_outside_box: 'Удари повз ворота',
    corners: 'Кутові удари',
    offsides: 'Офсайди',
    ball_possession: 'Володіння мячем',
    saves: 'Сейви',
    passes_total: 'Пасів у загальному',
    passes_accurate: 'Точні паси',
    yellow_cards: 'Жовті картки',
    red_cards: 'Червоні картки',
    date: 'Дата',
    shots: "Удари",
    success_shots: "Успішні дари",
    passes: "Паси",
    success_passes: "Успішні паси",
    dribbles: "Дриблінги",
    success_dribbles: "Успішні дриблінги",
    list_is_empty: 'Список пустий',
    social: 'Соціальні мережі',
    active_themes: 'Активні теми',
    disabled_themes: 'Заблоковані теми',
    emotion_success_added: 'Емоція успішно добавлена',
    news_language: 'Мова Новин',
    forum_language: "Мова Форуму",
    choose: 'Обрати',
    social_success_updated: 'Соціальні мережі успішно оновленні',
    load_data: 'Загрузити данні',
    chose_position: "Оберіть позицію",
    playerForm: 'Форма Гравця',
    goalkeeperForm: 'Форма Голкіпера',
    stadium: 'Стадіон',
    type: "Тип",
    shop: 'Магазин',
    box: 'Ящик',
    shop_type: 'Тип Товару',
    price: 'Ціна',
    coins: 'Очки',
    all_players: 'Усі гравці',
    you_already_have_this_element: 'Ви вже купили цей елемент',
    success_bought_check_your_inverter: 'Покупка успішно здійснена, провірьте ваш інвентарь',
    element_success_chosen: 'Елемент успішно обрано',
    congratulations: 'Вітаємо!!!',
    bux_success_bought: 'Бокс успішно куплено',
    rate_success_added: 'Прогноз успішно додано',
    rate_success_edited: 'Прогноз успішно змінено',
    please_check_content: "Будь ласка перевірте вміст новини",
    report_success_added: 'Репорт успішно доданий',
    id: 'id',
    players_statuses: 'Статуси гравців',
    return_to_club: 'Повернути у клуб',
    add_news_language: 'Додати мову для новин',
    trophies: 'Новини',
    trophy_room: 'Кімната трофеїв',
    edit_news_language: "Редагувати мову новин",
    add_forum_language: 'Додати мову форуму',
    edit_forum_language: 'Редагувати мову форуму',
    add_shop_element: 'Додати елемент у магазин',
    levels: 'Рівні',
    game_not_finish: 'Гра на завершилась',
    forum_reports: 'Репорти',
    market: "Магазин",
    shots_per_game: "Ударів за Ігру",
    shots_inside_box: "Ударів всередині штрафного майданчика",
    shots_outside_box: "Ударів за межами штрафного майданчика",
    passes_accurate_proc: "Процент точних пасів",
    fouls: "Фоли",
    matches: "Матчі",
    win: "Перемог",
    draw: "Нічиїх",
    lose: "Поразок",
    passes_proc: "Процент пасів",
    passes_per_game: "Пасів за гру",
    success_passes_per_game: "Успішні передачі за гру",
    missed_shots: "Удари мимо воріт",
    wrong_passes: "Неправильні паси",
    team_goals_home: "Голи команди вдома",
    team_goals_away: "Голи команди на виїзді",
    opponent_goal: "Гол суперника",
    team_shots: "Удари команди",
    opponent_shots: "Удари опонента",
    team_shots_on_goal: "Удари команди по воротах",
    team_passes: "Паси команди",
    opponent_passes: "Паси суперника",
    team_passes_accurate: "Точні командні паси",
    opponent_passes_accurate: "Точні паси суперника",
    team_corners: "Котові команди",
    opponent_corners: "Котові суперника",
    games_home: "Ігор дома",
    games_away: "Ігор на виїзді",
    goals_home: "Голів дома",
    goals_away: "Голів на виїзді",
    goals_per_game: "Голів за гру",
    goals_home_per_game: "Голів дома за гру",
    goals_away_per_game: "Голів на виїзді за гру",
    not_have: "Не отримали",
    one: "Один",
    two: "Два",
    more_two: "Більше двох",
    shots_home: "Ударів дома",
    shots_away: "Ударів на виїзді",
    goals_per_shot: "Голів з удара",
    goals_home_per_shot: "Голів вдома з удару",
    privacy_policy: "Політика конфіденційності",
    goals_away_per_shot: "Голів на виїзді з удару",
    shots_per_games: "Ударів за ігри",
    shots_home_per_games: "Ударів вдома за гру",
    shots_away_per_games: "Ударів на виїзді за гру",
    less_11: "Меньше 11",
    more_10: "Більше 10",
    more_15: "Більше 15",
    shots_on_goal_home: "Ударів по ворота вдома",
    shots_on_goal_away: "Ударів по ворота на виїзді",
    shots_per_goal: "Ударів на гол",
    shots_on_goal_home_per_goal: "Ударів по воротам вдома за гол",
    shots_on_goal_away_per_goal: "Ударів по воротам на виїзді за гол",
    shots_on_goal_per_game: "Ударів по воротам за гру",
    shots_on_goal_home_per_games: "Ударів по воротам вдома за гру",
    shots_on_goal_away_per_games: "Ударів по воротам на виїзді за гру",
    shots_inside_box_home: "Ударів у ворота вдома",
    shots_inside_box_away: "Ударів у ворота на виїзді",
    shots_inside_box_per_game: "Ударів у ворота за гру",
    shots_inside_box_home_per_games: "Ударів вдома у ворота за гру",
    shots_inside_box_away_per_games: "Ударів на виїзді у ворота за гру",
    shots_outside_box_home: "Ударів вдома за межі воріт",
    shots_outside_box_away: "Ударів на виїзді за межі воріт",
    shots_outside_box_per_games: "Ударів за межі воріт за гру",
    shots_outside_box_home_per_games: "Ударів вдома за межі воріт за гру",
    shots_outside_box_away_per_games: "Ударів на виїзді за межі воріт за гру",
    passes_home: "Пасів вдома",
    passes_away: "Пасів на виїзді",
    passes_per_goal: "Пасів за гол",
    passes_home_per_goal: "Пасів вдома за гол",
    passes_away_per_goal: "Пасів на виїзді за гол",
    passes_per_games: "Пасів за гру",
    passes_home_per_games: "Пасів вдома за гру",
    passes_away_per_games: "Пасів на виїзді за гру",
    passes_accurate_home: "Пасів точних вдома",
    passes_accurate_away: "Пасів точних на виїзді",
    passes_accurate_per_goal: "Пасів точних за гол",
    passes_accurate_home_per_goal: "Пасів точних вдома за гол",
    passes_accurate_away_per_goal: "Пасів точних на виїзді за гол",
    passes_accurate_per_games: "Пасів точних за гру",
    passes_accurate_home_per_games: "Пасів точних ввдома за гру",
    passes_accurate_away_per_games: "Пасів точних на виїзді за гру",
    corners_home : "Кутових вдома",
    corners_away: "Кутових на виїзді",
    corners_per_games: "Кутових за гру",
    corners_home_per_games: "Кутових вдома за гру",
    corners_away_per_games: "Кутових на виїзді за гру",
    saves_home: "Сейвів вдома",
    saves_away: "Сейвів на виїзді",
    saves_per_games: "Сейвів за гру",
    saves_home_per_games: "Сейвів вдома за гру",
    saves_away_per_games: "Сейвів на виїзді",
    fouls_home: "Фолів вдома",
    fouls_away: "Фолів на виїзді",
    fouls_per_games: "Фолів за гру",
    fouls_home_per_games: "Фолів вдома за гру",
    fouls_away_per_games: "Фолів на виїзді за гру",
    offsides_home: "Офсайди вдома",
    offsides_away: "Офсайди на виїзді",
    offsides_per_games: "Офсайди за гру",
    offsides_home_per_games: "Офсайди дома за гру",
    offsides_away_per_games: "Офсайди на виїзді за гру",
    yellow_cards_home: "Жовтих карток вдома",
    yellow_cards_away: "Жовтих карток на виїзді",
    yellow_cards_per_game: "Жовтих карток за гру",
    yellow_cards_home_per_game: "Жовтих карток вдома за гру",
    yellow_cards_away_per_game: "Жовтих карток на виїзді за гру",
    red_cards_home: "Червоних карток вдома",
    red_cards_away: "Червоних карток на виїзді",
    red_cards_per_game: "Червоних карток за гру",
    red_cards_home_per_games: "Червоних карток вдома за гру",
    red_cards_away_per_games: "Червоних карток на виїзді за гру",
    passes_accuracy: "Точні паси",
    key_passes: "Ключові паси",
    assists: "Асистів",
    dribbles_total: "Загальні дриблінг",
    dribbles_success: "Успішний дриблінг",
    dribbles_per_game: "Дриблінг за гру",
    games: "Ігри",
    minutes_per_game: "Хвилин за гру",
    game_per_goal: "Голів на гру",
    minutes_per_goal: "Хвилин на гол",
    passes_success: "Успішних пасів",
    fouls_committed: "Скоєних фолів",
    minutes_played: "Хвилин зіграно",
    shots_on_target: "Ударів у ціль",
    ball_accuracy: "Володіння м'ячем",
    shotstotal: "Ударів взагальному",
    minutes:"Хвилин",
    rating:"Рейтинг",
    passestotal:"Пасів взагальному",
    shotsongoal: "Ударів по воротах",
    passesaccuracy: "Точні паси",
    passeskey: "Ключові паси",
    dribblestotal: "Загальний дриблінг",
    dribblessuccess: "Успішний дриблінг",
    foulcommitted: "Вчинено фолів",
    fouldrawn: "Зароблено фолів",
    yellowcars: "Жовті картки",
    redcards: "Червоні картки",
    passesAccuracy: "Точність пасів",
    shotsTotal: "Ударів",
    shotsOnGoal: "ударів по воротах",
    passesTotal: "Пасів",
    passesKey: "Ключьові паст",
    dribblesTotal: "Дриблінгів",
    dribblesSuccess: "Успішний дриблінг",
    foulCommitted: "Фолів дійснених",
    foulDrawn: "Фолів зароблено",
    redCards: "Чевоних корток",
    yellowCars: "Жовтих карток",
    inverter: 'Інтвентарь',
    for_presentation_only: "винятково для презетації",
    login_name: 'Логін',
    search: 'Пошук',
    search_before: 'Попередній пошук',
    goalkeepers: 'Голкіпер',
    defenders: 'Захисники',
    midfielders: 'Півзахисники',
    attackers: 'Нападники',
    missed_list: 'Пропустять матч',
    filter: 'Фільтр',
    success_updated: 'Успішно оновлено',
    add_game: "Додати гру",
    sur_prize_title: "Команда Cruspher проводить сезонний розіграш призів. Здійснюй прогнози і піднімайся вгору у таблиці лідерів Cruspher, щоб отримати шанс виграти призи в кінці сезону.",
    hours_left_to_add_rate: 'годин(ни) лишилось щоб додати ставку'
  },
  [LOCALES.SPANISH]: {
    Beginner: "",
    players: 'Players',
    unknown_error: 'Un error desconocido ocurrió. Por favor, inténtelo de nuevo más tarde.',
    register: 'Registro',
    login: "Sobre nosotros",
    about_us: "Sobre nosotros",
    language: "Idioma",
    settings: 'Ajustes',
    save: 'Salvar',
    edit: 'Editar',
    defender: 'Dfensor',
    midfielder: 'Centrocampista',
    attacker: 'Hacia adelante',
    Defender: 'Dfensor',
    Midfielder: 'Centrocampista',
    Attacker: 'Hacia adelante',
    statistics: 'Estadísticas',
    add: 'Agregar',
    transfers: 'Transferencias',
    player_success_returned: 'El jugador regresó con éxito al club',
    players_empty_list: 'La lista de jugadores está vacía.',
    player_successfully_updated: 'Jugador actualizado con éxito',
    player_successfully_added: 'Jugador añadido con éxito',
    player_successfully_left_the_club: 'El jugador abandonó con éxito el club.',
    name: "Nombre",
    surname: 'Apellido',
    number: 'Número',
    choose_position: 'Elige una posición',
    select_player: 'Selecciona un jugador',
    leave_the_club: 'Dejar el club',
    position: 'Posición',
    player_from_api_stats: 'Jugador de API Stats',
    date_of_birth: 'Fecha de nacimiento',
    the_news_list_is_empty: "La lista de noticias está vacía.",
    remove: 'Eliminar',
    news_deleted_successfully: 'Noticias eliminadas con éxito',
    news_successfully_changed: 'Noticias cambiadas con éxito',
    news_successfully_added: "Noticias añadidas con éxito",
    add_news: 'Añadir noticia',
    edit_news: 'Editar noticia',
    title: "Título",
    content: 'Contenido',
    download: 'Descargar',
    image: 'Imágene',
    return: 'Regreso',
    the_topics_list_is_empty: 'La lista de temas está vacía',
    activate: 'Activar',
    deactivate: 'Desactivar',
    list_of_active_topics: 'Lista de temas activos',
    list_of_inactive_topics: 'Lista de temas inactivos',
    your_rating_is_missing: "Falta tu calificación",
    user_account: 'Cuenta de usuario',
    season_rating: 'Calificación de la temporada',
    data_changed_successfully: "Daten erfolgreich geändert",
    password_changed_successfully: 'das Passwort wurde erfolgreich geändert',
    name_and_surname: 'Name und Nachname',
    password: 'Passwort',
    new_password: 'Nueva contraseña',
    current_password: 'Contraseña actual',
    password_confirmation: 'confirmación de contraseña',
    forum: "Foro",
    an_application_for_updating_the_topic_name_has_been_submitted: 'Se ha enviado una solicitud para actualizar el nombre del tema',
    an_application_has_been_submitted_to_create_a_topic: 'Se ha enviado una solicitud para crear un tema',
    topic_title: 'Título del tema',
    the_message_list_is_empty: 'La lista de mensajes está vacía',
    message_text: 'mensaje de texto...',
    send: 'Enviar',
    profile: 'Perfil',
    cruspher: 'Cruspher',
    tables: 'Mesas',
    news: 'Noticias',
    player_stats: 'Estadísticas del jugador',
    contacts: 'Contactos',
    admin_menu: 'Menú',
    logout: 'Cerrar sesión',
    upload: 'Subir',
    view: 'Vista',
    email: "Correo electrónico",
    confirm_password: 'Confirmar Contraseña',
    success_register: 'Se ha registrado con éxito',
    confirm_your_email: 'Confirme su dirección de correo electrónico',
    success_edited: 'Cambiado con éxito',
    success_added: 'Agregado con éxito',
    success_removed: 'Eliminado con éxito',
    forecast: 'Pronostico',
    game: 'Juego',
    goals: 'Goles',
    shots_on_goal: 'Dispares a puerta',
    shots_total: 'Dispares totales',
    block_shoots: 'Dispares bloqueadas',
    shot_inside_box: 'Disparó de la puerta',
    shot_outside_box: 'Disparó fuera de la puerta',
    corners: 'Tiro de esquina',
    offsides: 'Fuera de juego',
    ball_possession: 'Posesión de balón',
    saves: 'Guardar',
    passes_total: 'Pases totales',
    passes_accurate: 'Pases exactos',
    yellow_cards: 'Tarjetas amarillas',
    red_cards: 'Tarjetas rojas',
    date: 'Fecha',
    shots: "Dispares",
    success_shots: "Dispares con éxito ",
    passes: "Pases",
    success_passes: "Pases con éxito",
    dribbles: "Regatea",
    success_dribbles: "Regates con éxito",
    list_is_empty: 'La lista está vacía',
    social: 'Redes sociales',
    active_themes: 'Temas activos',
    disabled_themes: 'Temas bloqueados',
    emotion_success_added: 'Emoción agregada con éxito',
    news_language: 'Idiomas de noticias',
    forum_language: "Idioma del foro",
    choose: 'Escoger',
    social_success_updated: 'Redes sociales actualizadas con éxito',
    load_data: 'Subir datos',
    chose_position: "Elige posición",
    playerForm: 'El Uniforme de jugador',
    goalkeeperForm: 'El Uniforme de portero',
    goalkeeper: "Goalkeeper",
    Goalkeeper: "Goalkeeper",
    stadium: 'Estadio',
    type: "Tipo",
    shop: 'Tienda',
    box: 'Caja',
    shop_type: 'Tipo de producto',
    price: 'Precio',
    coins: 'Puntos',
    all_players: 'Todos los jugadores',
    you_already_have_this_element: 'Ya compraste este producto',
    success_bought_check_your_inverter: 'Compra realizada , revisa tu inventario',
    element_success_chosen: 'Artículo seleccionado con éxito',
    congratulations: 'Felicidades!!!',
    bux_success_bought: 'Caja comprada con éxito',
    rate_success_added: 'Tarifa agregada con éxito',
    rate_success_edited: 'Tarifa cambiada con éxito',
    please_check_content: "Por favor revise el contenido de las noticias",
    report_success_added: 'Informe añadido con éxito',
    id: 'id',
    players_statuses: 'Estados de los jugadores',
    return_to_club: 'Volver al club',
    add_news_language: 'Agregar idioma para noticias',
    trophies: 'Botín',
    trophy_room: 'Cuarto de trofeos',
    edit_news_language: "Agregar idioma para las noticias",
    add_forum_language: 'Agregar idioma del foro',
    edit_forum_language: 'Cambiar el idioma del foro',
    add_shop_element: 'Agregar un artículo a la tienda',
    levels: 'Niveles',
    game_not_finish: 'El juego no ha terminado',
    forum_reports: 'informes del foro',
    market: "Tiros por juego",
    shots_per_game: "Tiros por juego",
    shots_inside_box: "Tiros dentro del área penal",
    shots_outside_box: "Tiros fuera del área penal",
    passes_accurate_proc: "Porcentaje de pases precisos",
    fouls: "Faltas",
    matches: "Partidos",
    win: "Victorias",
    draw: "Empates",
    lose: "Derrotas",
    passes_proc: "Pases porcentaje",
    passes_per_game: "Pases por partido",
    success_passes_per_game: "Asistencias exitosas",
    missed_shots: "Tiros fallados",
    wrong_passes: "Pases equivocados",
    team_goals_home: "Goles del equipo en casa",
    team_goals_away: "Goles del equipo fuera",
    opponent_goal: "Gol del oponente",
    team_shots: "Tiros de equipo",
    opponent_shots: "Tiros de oponente",
    team_shots_on_goal: "Tiros a puerta del equipo local",
    oponent_shots_on_goal: "Tiros a puerta del equipo visitante",
    team_passes: "Pases del equipo local",
    opponent_passes: " Pases del equipo visitante",
    team_passes_accurate: "Pases acertados del equipo local",
    opponent_passes_accurate: "Pases acertados del equipo visitante",
    team_corners: "Tiro de esquina del equipo local",
    opponent_corners: "Tiro de esquina del equipo visitante",
    games_home: "Partidos de local",
    games_away: "Partitos de visitante",
    goals_home: "Goles de local",
    goals_away: "Goles de visitante",
    goals_per_game: "Goles por partido",
    goals_home_per_game: " Goles por partido de local",
    goals_away_per_game: "Goles por partido de visitante",
    not_have: "No se marcaron",
    one: "Uno",
    two: "Dos",
    more_two: "Mas que dos",
    shots_home: "Tiros en casa",
    shots_away: "Tiros fuera",
    goals_per_shot: "Goles por Tiro",
    goals_home_per_shot: "Goles  por tiro en casa",
    privacy_policy: "Política de privacidad",
    goals_away_per_shot: "Goles de saque fuera de casa",
    shots_per_games: "Tiros por partidos",
    shots_home_per_games: "Tiros por partido de local",
    shots_away_per_games: "Tiros por partido de visitante",
    less_11: "Menos de 11",
    more_10: "Mas de 10",
    more_15: "Mas de 15",
    shots_on_goal_home: "Tiros a puerta en casa",
    shots_on_goal_away: "Tiros a puerta fuera de casa",
    shots_per_goal: "Tiros por gol",
    shots_on_goal_home_per_goal: "Tiros por gol en casa",
    shots_on_goal_away_per_goal: "Tiros por gol fuera de casa",
    shots_on_goal_per_game: "Tiros a puerta por partido",
    shots_on_goal_home_per_games: "Tiros a puerta en casa por partido",
    shots_on_goal_away_per_games: "Tiros a puerta fuera de casa por partido",
    shots_inside_box_home: "Tiros el gol fuera de casa",
    shots_inside_box_away: "Tiros el gol por partido",
    shots_inside_box_per_game: "Tiros el gol en casa por partido",
    shots_inside_box_home_per_games: "УTiros el gol en casa por partido",
    shots_inside_box_away_per_games: "Tiros el gol fuera de casa por partido",
    shots_outside_box_home: "Tiros en casa fuera de la portería",
    shots_outside_box_away: "Tiros en fuera de casa fuera de la portería",
    shots_outside_box_per_games: "Tiros fuera de la portería por partido",
    shots_outside_box_home_per_games: "Tiros en casa fuera de la portería por partido",
    shots_outside_box_away_per_games: "Tiros fuera de casa fuera de la portería por partido",
    passes_home: "Pases en casa",
    passes_away: "Pases fuera de casa",
    passes_per_goal: "Pases por gol",
    passes_home_per_goal: "Pases en casa por gol",
    passes_away_per_goal: "Pases fuera de casa por gol",
    passes_per_games: "Pases por partido",
    passes_home_per_games: " Pases en casa por partido",
    passes_away_per_games: "Pases fuera de casa por partido",
    passes_accurate_home: "Pases acertados en casa",
    passes_accurate_away: "Pases acertados fuera de casa",
    passes_accurate_per_goal: "Pases acertados por gol",
    passes_accurate_home_per_goal: "Pases acertados por gol en casa",
    passes_accurate_away_per_goal: "Pases acertados por gol fuera de casa",
    passes_accurate_per_games: "Pases acertados por partido",
    passes_accurate_home_per_games: "Pases acertados por partido en casa",
    passes_accurate_away_per_games: "Pases acertados por partido fuera de casa",
    corners_home : "Tiros de esquina en casa",
    corners_away: "Tiros de esquina fuera de casa",
    corners_per_games: "Tiros de esquina por partido",
    corners_home_per_games: "Tiros de esquina por partido en casa",
    corners_away_per_games: "Tiros de esquina por partido fuera de casa",
    saves_home: "Сейвів вдома",
    saves_away: "Сейвів на виїзді",
    saves_per_games: "Сейвів за гру",
    saves_home_per_games: "Сейвів вдома за гру",
    saves_away_per_games: "Сейвів на виїзді",
    fouls_home: "Faltas en casa",
    fouls_away: "Faltas fuera de casa",
    fouls_per_games: "Faltas por partido",
    fouls_home_per_games: "Faltas por partido en casa",
    fouls_away_per_games: "Faltas por partido fuera de casa",
    offsides_home: "Fuera de juego en casa",
    offsides_away: "Fuera de juego fuera de casa",
    offsides_per_games: "Fuera de juego por partido",
    offsides_home_per_games: "Fuera de juego por partido en casa",
    offsides_away_per_games: "Fuera de juego por partido fuera de casa",
    yellow_cards_home: "Tarjetas amarillas en casa",
    yellow_cards_away: "Tarjetas amarillas en casa fuera de casa",
    yellow_cards_per_game: "Tarjetas amarillas por partido",
    yellow_cards_home_per_game: "Tarjetas amarillas por partido en casa",
    yellow_cards_away_per_game: "Tarjetas amarillas por partido fuera de casa",
    red_cards_home: "Tarjetas rojas en casa",
    red_cards_away: "Tarjetas rojas fuera de casa",
    red_cards_per_game: "Tarjetas rojas por partido",
    red_cards_home_per_games: "Tarjetas rojas por partido en casa",
    red_cards_away_per_games: "Tarjetas rojas por partido fuera de casa",
    passes_accuracy: "Pases acertados",
    key_passes: "Pases clave",
    assists: "Pases con asistencia",
    dribbles_total: "Regate total",
    dribbles_success: "Regate exitoso",
    dribbles_per_game: "Regate por partido",
    games: "Partidos",
    minutes_per_game: "Minutos por juego",
    game_per_goal: "Goles por partido",
    minutes_per_goal: "Minutos para gol",
    passes_success: "Pases exitosos",
    fouls_committed: "Faltas cometidas",
    minutes_played: "Minutos jugados",
    shots_on_target: "Tiros en el objetivo",
    ball_accuracy: "Posesión de la pelota",
    shotstotal: "Tiros total",
    minutes:"Minutos",
    rating:"Clasificación",
    passestotal:"Pases total",
    shotsongoal: "Tiros a puerta",
    passesaccuracy: "Pases acertados",
    passeskey: "Pases clave",
    dribblestotal: "Regate total",
    dribblessuccess: "Regate exitoso",
    foulcommitted: "Faltas cometidas",
    fouldrawn: "Faltas",
    yellowcars: "Tarjetas amarillas",
    redcards: "Tarjetas rojas",
    passesAccuracy: "Precisión de los pases",
    shotsTotal: "Tiros",
    shotsOnGoal: "Tiros a puerta",
    passesTotal: "Pases",
    passesKey: "Pases clave",
    dribblesTotal: "Regate",
    dribblesSuccess: "Exito regate",
    foulCommitted: "Foul Committed",
    foulDrawn: "Foul Drawn",
    redCards: "Tarjetas rojas",
    yellowCars: "Tarjetas amarillas",
    for_presentation_only: "solo para presentación",
    login_name: 'Acceso',
    search: 'Búsqueda',
    search_before: 'Buscar antes',
    goalkeepers: 'Porteros',
    defenders: 'Defenders',
    midfielders: 'Centrocampistas',
    attackers: 'Atacantes',
    missed_list: 'No estará presente',
    filter: 'filtrar',
    success_updated: 'actualizado exitosamente',
    add_game: 'Add game',
    sur_prize_title: "The Cruspher team is holding a seasonal prize draw. Make as many predictions as possible and climb up the leaderboard for a chance to win prizes at the end of the season.",
    hours_left_to_add_rate: 'hour(s) left to add rate',
  },

     [LOCALES.GERMAN]: {
    unknown_error: "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    register: "Anmeldung",
    login: "Einloggen",
    about_us: "über uns",
    language: "Sprache",
    settings: 'Einstellungen',
    save: 'Speichern',
    edit: 'Bearbeiten',
    defender: 'Verteidiger',
    midfielder: 'Mittelfeldspieler',
    attacker: 'Nach vorne',
    statistics: 'Statistiken',
    add: 'Addieren',
    transfers: 'Überweisungen',
    player_success_returned: 'Der Spieler kehrte erfolgreich zum Verein zurück',
    players_empty_list: 'Die Spielerliste ist leer',
    player_successfully_updated: 'Player erfolgreich aktualisiert',
    player_successfully_left_the_club: 'Der Spieler hat den Verein erfolgreich verlassen',
    player_successfully_added: 'Der Spieler hat den Verein erfolgreich verlassen',
    name: "Name",
    surname: 'Nachname',
    number: 'Anzahl',
    choose_position: 'Wählen Sie eine Stelle',
    select_player: 'Wählen Sie einen Spieler aus',
    leave_the_club: 'Verlassen Sie den Verein',
    position: 'Position',
    player_from_api_stats: 'Spieler aus API-Statistiken',
    date_of_birth: 'Geburtsdatum',
    the_news_list_is_empty: "Die Nachrichtenliste ist leer",
    remove: 'Entfernen',
    news_deleted_successfully: 'Nachrichten erfolgreich gelöscht',
    news_successfully_changed: 'Nachrichten erfolgreich geändert',
    news_successfully_added: "Nachrichten erfolgreich hinzugefügt",
    add_news: 'Neuigkeiten hinzufügen',
    edit_news: 'Nachrichten bearbeiten',
    title: "Titel",
    content: 'Inhalt',
    download: 'Herunterladen',
    image: 'Bild',
    return: 'Zurückkehren',
    the_topics_list_is_empty: 'Die Themenliste ist leer',
    activate: 'aktivieren Sie',
    deactivate: 'Deaktivieren',
    list_of_active_topics: 'List of active topics',
    list_of_inactive_topics: 'Liste der inaktiven Themen',
    your_rating_is_missing: "Ihre Bewertung fehlt",
    user_account: 'Benutzerkonto',
    season_rating: 'Staffelwertung',
    data_changed_successfully: 'Los datos cambiaron con éxito',
    password_changed_successfully: 'Contraseña cambiada con éxito',
    name_and_surname: 'Clave',
    new_password: "Neues Kennwort",
    current_password: 'Aktuelles Passwort',
    password_confirmation: 'Passwort Bestätigung',
    forum: "Forum",
    an_application_for_updating_the_topic_name_has_been_submitted: 'Ein Antrag auf Aktualisierung des Themennamens wurde eingereicht',
    an_application_has_been_submitted_to_create_a_topic: 'Es wurde ein Antrag auf Erstellung eines Themas gestellt',
    topic_title: 'Thementitel',
    the_message_list_is_empty: 'Nachrichtenliste ist leer',
    message_text: 'nachrichtentext...',
    send: 'Schicken',
    profile: 'Profil',
    cruspher: 'Cruspher',
    tables: 'Tische',
    news: 'Nachrichten',
    player_stats: 'Spielerstatistiken',
    contacts: 'Kontakte',
    admin_menu: 'Admin-Menü',
    logout: 'Ausloggen',
    upload: 'Hochladen',
    view: 'Ansicht',
    email: "Емейл",
    confirm_password: 'Passwort bestätigen',
    success_register: 'Sie haben sich erfolgreich registriert',
    confirm_your_email: 'Bestätigen Ihre E-Mail',
    success_edited: 'erfolgreich geändert',
    success_added: 'erfolgreich hinzugefügt',
    success_removed: 'erfolgreich gelöscht',
    forecast: 'die Prognose',
    game: 'das Spiel',
    goals: 'das Tor',
    shots_on_goal: 'Удар по воротам',
    shots_total: 'ein Schuss aufs Tor',
    block_shoots: 'die Schüsse insgesamt',
    shot_inside_box: 'blockierte Schüsse',
    shot_outside_box: 'am Tor vorbeischießen',
    corners: 'die Eckbälle',
    offsides: 'das Abseits',
    ball_possession: 'die Ballbesitz',
    saves: 'saves',
    passes_total: 'die Zuspiele insgesamt',
    passes_accurate: 'exakte Zuspiele',
    yellow_cards: 'gelbe Karten',
    red_cards: 'rote Karten',
    date: 'das Datum',
    shots: "die Schüsse",
    success_shots: "die erfolgreichen Schüsse",
    passes: "die Zuspiele",
    success_passes: "erfolgreichen Zuspiele",
    dribbles: "die Ballführungen",
    success_dribbles: "die erfolgreichen Ballführungen",
    list_is_empty: 'die Liste ist leer',
    social: 'soziale Netzwerke',
    active_themes: 'die aktiven Themen',
    disabled_themes: 'gesperrte Themen',
    emotion_success_added: 'die Emotion ist erfolgreich hinzugefügt',
    news_language: 'die Nachrichtensprache',
    forum_language: "die Forumsprache",
    choose: 'wählen',
    social_success_updated: 'Die sozialen Netzwerken sind erfolgreich upgedatet',
    load_data: 'die Daten herunterladen',
    chose_position: "wählen die Position",
    playerForm: 'die Form des Spielers',
    goalkeeperForm: 'die Form des Goalkeepers',
    stadium: 'das Stadion',
    type: "der Typ",
    shop: 'der Laden',
    box: 'die Box',
    shop_type: 'die Warenart',
    price: 'die Preise',
    coins: 'der Punkt',
    all_players: 'alle Spieler',
    you_already_have_this_element: 'Sie haben dieses Element schon gekauft',
    success_bought_check_your_inverter: 'der Kauf war erfolgreich, überprüfen Sie Ihren Lagerbestand',
    element_success_chosen: 'das Element ist erfolgreich gewählt',
    congratulations: 'Gratulation!!!',
    bux_success_bought: 'die Box ist erfolgreich gekauft',
    rate_success_added: 'die Prognose ist erfolgreich hinzugefügt',
    rate_success_edited: 'die Prognose ist erfolgreich verändert',
    please_check_content: "Bitte überprüfen Sie den Nachrichteninhalt",
    report_success_added: 'der Bericht ist erfolgreich hinzugefügt',
    id: 'id',
    players_statuses: 'die Spielerstatus',
    return_to_club: 'zurück zum Verein',
    add_news_language: 'die Nachrichtensprache hinzufügen',
    trophies: 'die Trophäen',
    trophy_room: ' das Trophäenzimmer',
    edit_news_language: "die Nachrichtensprache ändern",
    add_forum_language: 'die Forumsprache hinzufügen ',
    edit_forum_language: 'die Forumsprache ändern',
    add_shop_element: 'ein Element zum Laden hinzufügen',
    levels: 'die Niveaus',
    game_not_finish: 'das Spiel ist noch nicht zu Ende',
    forum_reports: '*',
    market: "Market"
  },
}