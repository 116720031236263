import React from 'react'
import c from './style.module.scss'
import {Wrap} from "../../components/cruspher-ui/wrappers/wrap";
import {useIntl} from "react-intl";
import {GridBox} from "../../components/cruspher-ui/box/grid-box";

const privacyData = [
  {
    title: 'Загальні положення',
    content: [
      "1.1 Використання Сайту Користувачем означає згоду із сучасною Політикою конфіденційності та умовами обробки персональних даних Користувача.",
      "1.2 У разі неузгодження з умовами Політики конфіденційності Користувач повинен припинити використання Сайту.",
      "1.3 Данна Політика конфіденційності застосовується тільки до цього Сайту. Адміністрація Сайту не контролює і не несе відповідальності за сайти третіх осіб, на які Користувач може переходити за посиланнями, доступними на цьому сайті.",
      "1.4. Адміністрація Сайту не перевіряє достовірність персональних даних, наданих Користувачем Сайту."
    ]
  },
  {
    title: 'Предмет політики конфіденційності',
    content: [
      "2.1 Данна Політика конфіденційності встановлює зобов’язання Адміністрації Сайту щодо нерозголошення та забезпечення режиму захисту конфіденційності персональних даних, які Користувач надає за запитом Адміністрації Сайту при реєстрації на Сайті.",
      "Персональні дані, призначені для обробки в рамках цієї Політики, надаються Користувачем шляхом заповнення реєстраційної форми на Сайті та включають в себе наступну конфіденційну інформацію:",
      "2.2.1. Фамілію, Ім'я Пользователя;",
      "2.2.2. Адрес електронної почти (e-mail).",
    ]
  },
  {
    title: 'Цілі збору персональних данних користувача',
    content: [
      "Цілі збору персональних данних користувача",
      "3.1. Персональні дані Користувача Адміністрація Сайту може використовуватись з метою:",
      "3.1.1. Ідентифікації Користувача, зареєстрованого на Сайті.",
      "3.1.2. Надання Користувачеві доступу до персоналізованих ресурсів Сайту.",
      "3.1.3. Встановлення з Користувачем зворотного зв'язку, включаючи направлення повідомлень, запитів щодо використання Сайту, надання послуг, обробка запитів та заявок від Користувача.",
      "3.1.3. Надання Користувачеві ефективної клієнтської та технічної підтримки у разі виникнення проблем, пов'язаних з використанням Сайту."
    ]
  },
  {
    title: 'Способи і терміни обробки персоналих данних',
    content: [
      "4.1. Обробка персональних даних Користувача здійснюється без обмеження терміну будь-яким законним способом, у тому числі в інформаційних системах персональних даних з використанням засобів автоматизації або без використання таких засобів.",
      "4.2. Адміністрація Сайту вживає необхідних організаційних та технічних заходів для захисту персональної інформації Користувача від неправомірного або випадкового доступу, знищення, зміни, блокування, копіювання, розповсюдження, а також від інших неправомірних дій третіх осіб.",
     ]
  },
  {
    title: 'Обовязки Адміністрації',
    content: [
      "5.1. Використовувати отриману інформацію виключно для цілей, зазначених у пункті 4 цієї Політики конфіденційності.",
      "5.2. Забезпечити зберігання конфіденційної інформації в таємниці, не розголошувати без попереднього письмового дозволу Користувача, а також не здійснювати продаж, обмін, опублікування чи розголошення іншими можливими способами переданих персональних даних Користувача.",
      "5.3. Вживати запобіжних заходів для захисту конфіденційності персональних даних Користувача згідно з порядком, який зазвичай використовується для захисту такого роду інформації в існуючому діловому обороті.",
      "5.4. Здійснити блокування персональних даних, що належать до відповідного Користувача, з моменту звернення або запиту Користувача або його законного представника або уповноваженого органу захисту прав суб'єктів персональних даних на період перевірки у разі виявлення недостовірних персональних даних або неправомірних дій.",
    ]
  },
  {
    title: 'Обов’язки Користувача',
    content: [
      "6.1. Під час використання сайту надати всю необхідну інформацію щодо персональних даних для користування Сайтом.",
      "6.2. Оновлювати, доповнювати надану інформацію щодо персональних даних у випадку її зміни.",
      "6.3. У разі не згоди з цими умовами Політики конфіденційності припинити користуватися сайтом."
    ]
  },
  {
    title: 'Додаткові умови',
    content: [
      "7.1. Адміністрація Сайту має право вносити зміни до цієї Політики конфіденційності без згоди Користувача.",
      "7.2. Нова Політика конфіденційності набирає чинності з моменту її розміщення на Сайті, якщо інше не передбачено новою редакцією Політики конфіденційності.",
    ]
  },
]

const PrivacyPage = () => {
  const intl = useIntl()

  return (
    <Wrap vPadding="30px" maxWidth="800px">
      <div className={c.wrap}>
        <h1 className={c.privacyTitle}>{intl.formatMessage({id: 'privacy_policy'})}</h1>
        {
          privacyData.map((block) => (
            <GridBox key={block.title} rowGap="15px">
              <h4 className={c.title}>{block.title}</h4>

              <GridBox rowGap="5px">
                {
                  block.content.map((text, index) => (
                    <p key={block.title + index} className={c.text}>
                      {text}
                    </p>
                  ))
                }
              </GridBox>
            </GridBox>
          ))
        }
      </div>
    </Wrap>
  )
}

export {
  PrivacyPage
}